<template>
  <div>
    <div v-if="formActive === false">
      <p :class="{label: true, success: success}">
        {{ labelMessage }}
      </p>
      <BaseButton
        type="cta"
        @click="toggleRegistration"
      >
        Apply for registration
      </BaseButton>
      <!-- <span class="secondary">Learn more</span> -->
    </div>
    <div v-else>
      <p class="label">
        To apply, please select a DEA and medical license
      </p>
      <ProductRegisterForm
        :vendor-id="getProduct.vendorId"
        @toggleForm="handleSuccess"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseButton from '@/components/common/BaseButton.vue';
import ProductRegisterForm from '@/components/marketplace/product/ProductRegisterForm.vue';

export default {
  name: 'ProductRegister',
  components: {
    BaseButton,
    ProductRegisterForm,
  },
  data() {
    return {
      formActive: false,
      labelMessage: 'This is a regulated product and requires registration',
      success: false,
    };
  },
  computed: {
    ...mapGetters({
      getProduct: 'marketplaceProduct/getProduct',
    }),
  },
  methods: {
    toggleRegistration() {
      this.formActive = !this.formActive;
    },
    handleSuccess() {
      this.toggleRegistration();
      this.labelMessage = 'Your application has been received. If you would like to apply with additional licenses, you may do so now.';
      this.success = true;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.label {
  font-size: 0.8em;
  margin-bottom: 5px;
}
.secondary {
  font-size: .9em;
  color: $simpatra-orange;
  text-decoration: underline;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
}
.success {
  color: $success-green;
  font-size: .9em;
  margin-bottom: 10px;
}
</style>
