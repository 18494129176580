<template>
  <div>
    <div>
      <OAuthLink>
        <BaseButton type="cta">
          Sign in
        </BaseButton>
      </OAuthLink>
      <span>
        <span class="or">or</span>
        <a
          :href="registerUrl"
          class="secondary"
        >Register for an account</a>
      </span>
    </div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import BaseButton from '@/components/common/BaseButton.vue';
import OAuthLink from '@/components/common/OAuthLink.vue';

export default {
  name: 'ProductSignIn',
  components: {
    BaseButton,
    OAuthLink,
  },
  data() {
    return {
      isPatient: false,
    };
  },
  computed: {
    registerUrl() {
      if (VueCookies.get('isPatient')) {
        return `${process.env.VUE_APP_AUTH_ROOT}/signup/patient`;
      } return `${process.env.VUE_APP_AUTH_ROOT}/signup`;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.or {
  font-size: 0.9em;
  margin: 0 10px;
}
.secondary {
  font-size: .9em;
  color: $simpatra-orange;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
</style>
