<template>
  <el-form v-loading="loading" class="registration-form" label-position="top">
    <div class="form-selects">
      <el-form-item class="form-item" label="DEA License">
        <el-select v-model="selectedDea" placeholder="Select DEA">
          <el-option v-for="(license, index) in deaLicenses" :key="index" :label="license.name" :value="license.id" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="Medical License">
        <el-select v-model="selectedMedical" placeholder="Select Medical">
          <el-option v-for="license in medicalLicenses" :key="license.id" :label="license.name" :value="license.id" />
        </el-select>
      </el-form-item>
    </div>
    <div class="registration-form-btn">
      <router-link
        v-if="locationLicensesEmpty"
        :to="{
          path: `/dashboard/${getCurrentSelectedLocation}/settings/licenses`,
        }"
        class="upload-license-btn"
      >
        Need New or Additional Licenses? Upload here
      </router-link>
      <div class="m-top-10">
        <BaseButton type="cta" :disabled="notValidated" @click="handleFormSubmit"> Request Approval </BaseButton>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isEmpty } from "lodash";
import BaseButton from "@/components/common/BaseButton.vue";

export default {
  name: "ProductRegisterForm",
  components: {
    BaseButton,
  },
  props: {
    vendorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      deaLicenses: [],
      medicalLicenses: [],
      selectedDea: "",
      selectedMedical: "",
    };
  },
  computed: {
    ...mapGetters(["getOrgId", "getSelectedCurrentLocationId", "getCurrentSelectedLocation"]),
    ...mapGetters("accountsModule", ["getDeaLicenses"]),
    ...mapGetters("accountsModule", ["getDeaLicenses", "getMedicalLicenses"]),
    notValidated() {
      return this.selectedDea === "" || this.selectedMedical === "";
    },
    locationLicensesEmpty() {
      return isEmpty(this.getDeaLicenses) || isEmpty(this.getMedicalLicenses);
    },
  },
  mounted() {
    this.getDeaLicenses.forEach((license) => {
      this.deaLicenses.push({ name: license.licenseName, id: license.id });
    });
    this.getMedicalLicenses.forEach((license) => {
      this.medicalLicenses.push({ name: license.licenseName, id: license.id });
    });
  },
  methods: {
    ...mapActions(["addVendorRegistration"]),
    async handleFormSubmit() {
      this.loading = true;
      await this.addVendorRegistration({
        orgId: this.getOrgId,
        locationId: this.getSelectedCurrentLocationId,
        deaLicenseId: this.selectedDea,
        medicalLicenseId: this.selectedMedical,
        vendorId: this.vendorId,
      });
      this.loading = false;
      this.$emit("toggleForm");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-license-btn {
  color: #f05a28;
  font-size: 11px;
}

.el-form-item {
  margin-bottom: 0;
}
.registration-form {
  align-items: center;
  .form-selects {
    display: flex;
    width: 100%;
  }
  .form-item {
    min-width: 200px;
    margin-right: 15px;
  }
}
::v-deep .el-form-item__label {
  line-height: 16px;
}
</style>
