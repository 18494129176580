<!-- eslint-disable -->
<template>
  <div class="share-toggle">
    <div
      v-if="!isRestockDetails"
      class="social_share-toggle"
      @click="active = !active">
      <font-awesome-icon
        :icon="['fa','share-alt']" />
        Share
    </div>
    <div class="product-drawer-icon">
      <div
      class="product-drawer-icon"
      v-if="isRestockDetails"
      @click="active = !active">
      <div class="share">
      <font-awesome-icon  class="social_share-restock-toggle"
        :icon="['fa','share-alt']" />
        </div>
        </div>
        <social-sharing
          v-if="active"
          :url="productUrl"
          :title="product.name"
          :description="product.description"
          inline-template>
      <div class="social_networks_drawer">
          <network network="email" :class="$parent.myClasses">
            <div class="share" style="
                  width: 25px;
                  height: 25px;
                  border-radius: 50%;
                  background-color: #f05a28;
                  color: white;
                  padding-left: 5px;
                  cursor:pointer;
                  margin: 5px;">
            <font-awesome-icon
              class="drawer-i"
              icon="envelope" />
              </div>
              <!-- Email -->
          </network>
        <network network="linkedin" :class="$parent.myClasses">
          <div class="share" style="
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: #f05a28;
                color: white;
                padding-left: 7px;
                cursor:pointer;
                margin: 5px;">
          <font-awesome-icon
          class="drawer-i"
            :icon="['fab','linkedin-in']" />
            </div>
          <!-- LinkedIn -->
        </network>
        <network network="sms" :class="$parent.myClasses">
          <div class="share" style="
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: #f05a28;
                color: white;
                padding-left: 9px;
                cursor:pointer;
                margin: 5px;">
          <font-awesome-icon
           class="drawer-i"
            :icon="['fa', 'mobile-alt']" />
            </div>
          <!-- SMS -->
        </network>
        <network network="whatsapp" :class="$parent.myClasses">
          <div class="share" style="
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: #f05a28;
                color: white;
                padding-left: 7px;
                margin: 5px;
                cursor:pointer;
                outline: none;">
          <font-awesome-icon
           class="drawer-i"
            :icon="['fab', 'whatsapp']" />
            </div>
          <!-- Whatsapp -->
        </network>
      </div>
    </social-sharing>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSocial',
  props: {
    product: {
      type: Object,
      required: true,
    },
    isRestockDetails: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    myClasses() {
      return ['social_item'];
    },
    productUrl() {
      return window.location.href;
    },
  },
};
</script>

<style lang="scss" scoped>
.share {
  width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #d4d2d2;
    padding: 5px 5px 10px 10px;
    margin-top: -5px;
}
.drawer-i {
      width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #d4d2d2;
    color: $orange;
}
.product-drawer-icon {
  display: flex;
}
.social_share-restock-toggle {
  color: $orange;
  &:hover {
      cursor:pointer;
    }
}

  .social_share-toggle {
    padding: 5px 10px;
    background: $grey;
    border-radius: 4px;
    display: inline-block;
    font-size: .8em;
    &:hover {
      cursor:pointer;
      background: $dark-grey;
    }
  }
  .social_networks_drawer {
    border-radius: 50px;
    padding-left: 9px;
    margin-left: 20px;
    margin-top: -5px;
    width: 160px;
    height: 35px;
    background-color: #d4d2d2;
    display: flex;
    flex-wrap: wrap;
  }
  .social_networks {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .social_item {
    margin-right: 20px;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  .share-toggle {
    display: flex;
  }
</style>
