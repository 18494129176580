<template>
  <div>
    <div v-if="getSubscribed === false">
      <p class="label">
        Out of stock
      </p>
      <div>
        <BaseButton
          type="cta"
          @click="subscribe"
        >
          Notify me when back in stock
        </BaseButton>
        <!-- <span class="secondary">Learn more</span> -->
      </div>
    </div>
    <div
      v-else
      class="success-wrapper"
    >
      <p class="success">
        Thank you for subscribing to stock notifications!<br>
        You will be emailed when this product is back in stock.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/common/BaseButton.vue';

export default {
  name: 'ProductRegister',
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters({
      getSubscribed: 'marketplaceProduct/getSubscribed',
    }),
  },
  methods: {
    ...mapActions({
      subscribe: 'marketplaceProduct/subscribeToNotifications',
    }),
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.label {
  font-size: 0.8em;
  margin-bottom: 5px;
}
.secondary {
  font-size: .9em;
  color: $simpatra-orange;
  text-decoration: underline;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
}
.success-wrapper {
  height: 60px;
}
.success {
  color: $success-green;
  font-size: .9em;
}
</style>
